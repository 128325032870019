const leftSideMenu = document.getElementById('left-side-menu');
const rightSideMenu = document.getElementById('right-side-menu');
const bannerContainer = document.getElementById('banner');
const pageHeader = document.getElementById('website-header');
// const sampleHomepageSquare = document.getElementById('sample-square');

document.addEventListener('DOMContentLoaded', () => {
    // As soon as the page is loaded, gather data to display
    fetch('/content.json')
    .then((data) => data.json())
    .then((data) => {
        for (let i=0; i < (data.left || []).length; i++) {
            leftSideMenu.append(new SideMenuItem(data.left[i]));
            /* const clone = sampleHomepageSquare.cloneNode(true);
            sampleHomepageSquare.parentNode.insertBefore(clone, sampleHomepageSquare.nextSibling);
            clone.update(data.left[i].image, data.left[i].title, data.left[i].subtitle); */
        }
        for (let i=0; i < (data.right || []).length; i++) {
            rightSideMenu.append(new SideMenuItem(data.right[i]));
        }
        for (let i=0; i < (data.banner || []).length; i++) {
            if (i == 1) {
                bannerContainer.append(clonePageHeader());
            }
            bannerContainer.append(new BannerItem(data.banner[i]));
        }
        bannerContainer.append(new BannerItem(data.banner[0]));
        bannerContainer.append(clonePageHeader());
        bannerContainer.append(new BannerItem(data.banner[1]));    
    });
});

function clonePageHeader() {
    const r = pageHeader.cloneNode(true);
    r.classList.remove('hidden');
    return r;
}